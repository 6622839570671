import React from 'react';
import ReactDOM from 'react-dom/client';
import styled from 'styled-components';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Create a Title component that'll render an <h1> tag with some styles
const Title = styled.h1`
  font-size: 1.5em;
  color: palevioletred;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const HeadWrapper = styled.section`
  height: 5vh;
  background: papayawhip;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExplainWrapper = styled.section`
  height: 8vh;
  background: papayawhip;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExplainTextWrapper = styled.section`
  width: 70vw;
  background: papayawhip;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SurvayWrapper = styled.section`
  height: 77vh;
  background: papayawhip;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FootWrapper = styled.section`
  height: 8vh;
  background: papayawhip;
  display: flex;
  align-items: center;
  justify-content: center;
`;

root.render(
  <React.StrictMode>
    <HeadWrapper>
      <Title>
        LAB254 Product support
      </Title>      
    </HeadWrapper>
    <ExplainWrapper>
      <ExplainTextWrapper>
        Need support? We love our users. Have a question or need help with your experience, select a product below and send questions for quick guidance and immediate support.
      </ExplainTextWrapper>
    </ExplainWrapper>
    <SurvayWrapper>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc_VV1r9QCUc_x0ZxsZ9YXR9HM1HjHbSD2iLVZpsoDm4yClng/viewform?embedded=true" width= "100%" height= "100%" frameBorder="0">로드 중…</iframe>
    </SurvayWrapper>
    <FootWrapper>
      2021 lab254 Co., Ltd.
    </FootWrapper>
  </React.StrictMode>
);
